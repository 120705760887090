import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Home from './components/Home';
import Unsubscribe from './components/Unsubscribe';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/unsubscribe/:email" element={<Unsubscribe />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
