import { useParams } from "react-router-dom";
import Data from '../data/Data';
import { useState, useEffect } from "react";
import Header from './Header';

function Unsubscribe() {
    const { email } = useParams();
    const [response, setResponse] = useState('');

    useEffect(() => {
        async function unsubscribe() {
            try {
                await Data.unsubscribe(email);
                setResponse('Successful Unsubscription!');
            } catch (ex) {
                setResponse('Unsuccessful Unsubscription!');
            }
    
            setTimeout(() => {
                window.location = '/';
            }, 2000);
        }
        
        unsubscribe();
    }, [email]);

    return (
        <div className="Unsubscribe d-flex justify-content-center">
            <Header />
            {response !== '' && (
                <div>
                    <h1>{response}</h1>
                    <p>We will redirect you to the main page...</p>
                </div>
            )}
        </div>
    )
}

export default Unsubscribe;
