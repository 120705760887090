class Data {
    static url = "https://api-dev.ubives.com";
    
    static async subscribe(email) {
        const response = await fetch(`${Data.url}/newsletter/subscribe`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "email": email,
                "language": navigator.language.split('-')[0],
                "token": process.env.REACT_APP_GOOGLE_RECAPTCHA_TOKEN
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

    static async unsubscribe(email) {
        const response = await fetch(`${Data.url}/newsletter/unsubscribe/${email}`, {
            method: 'DELETE'
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

    static async getNews() {
        const response = await fetch(`${Data.url}/news`);
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }
}

export default Data;