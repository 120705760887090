import { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Logo from '../assets/logo.png';
import LogoWhite from '../assets/logo-white.png';

gsap.registerPlugin(ScrollTrigger);

function Header() {
    const [logoSrc, setLogoSrc] = useState(LogoWhite);

    useEffect(() => {
        gsap.fromTo('nav', {
            backgroundColor: '#1c1c1cd2',
            border: 'none',
        }, {
            backgroundColor: '#ffffffd2',
            borderBottom: '1px solid rgb(229, 229, 229)',
            ease: 'power1.inOut',
            scrollTrigger: {
                // trigger: '#news',
                trigger: '#subscribe', // temporary
                start: '-20% start',
                end: '-10% start',
                scrub: true
            },
        });

        gsap.fromTo('nav', {
            backgroundColor: '#ffffffd2',
            borderBottom: '1px solid rgb(229, 229, 229)',
        }, {
            backgroundColor: '#1c1c1cd2',
            border: 'none',
            ease: 'power1.inOut',
            scrollTrigger: {
                trigger: '#our-team',
                start: '-5% start',
                end: 'start start',
                scrub: true,
            },
        });

        gsap.fromTo('nav', {
            backgroundColor: '#ffffff00',
            border: 'none'
        }, {
            backgroundColor: '#ffffffd2',
            borderBottom: '1px solid rgb(229, 229, 229)',
            ease: 'power1.inOut',
            scrollTrigger: {
                trigger: '#landing',
                start: '0%',
                end: '5%',
                scrub: true,
            },
        });

        gsap.to('nav', {
            scrollTrigger: {
                trigger: '#landing',
                start: '2.5%',
                scrub: true,
                onEnter: () => setLogoSrc(Logo),
                onLeaveBack: () => setLogoSrc(LogoWhite)
            },
        });

        gsap.fromTo('.btn-ubives', {
            // backgroundColor: '#f4f4f4',
            backgroundColor: '#f4f4f4',
            color: '#723be5'
        }, {
            // backgroundColor: '#723be5',
            backgroundColor: '#723be5',
            color: '#f4f4f4',
            scrollTrigger: {
                trigger: '#landing',
                start: '0%',
                end: '5%',
                scrub: true,
            }
        });
    }, []);

    return (
        <div className="Header">
            <nav className="navbar fixed-top navbar-expand-lg">
                <div className="container-fluid">
                    <a className="navbar-brand p-0 mb-2" href="/">
                        <img src={logoSrc} alt="Ubives" />
                    </a>
                    <div className='d-flex justify-content-end'>
                        <button key="submit" type="primary" onClick={() => window.location = 'https://ubives-partner-portal.web.app/'} className='btn-ubives'>
                            Get started
                        </button>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Header;